import React, { useState } from 'react';
import axios from 'axios';

const ResetPasswordRequest = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
 

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const response = await axios.post('https://mybackend-new.onrender.com/api/users/reset-password-request', { email });
      const audio = new Audio('./sound.wav'); // Provide the correct path or URL to the sound
      audio.play();
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response?.data?.message || 'An unexpected error occurred');
    }
    finally {
      setIsSubmitting(false); // Reset the loading state
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4 bg-gray-100">
      <div className="w-full max-w-md bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-4 text-center">Reset Password Request</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
         <button
  type="submit"
  disabled={isSubmitting}
  className={`w-full py-3 text-white font-semibold rounded-lg transition-all duration-300 
    ${isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-gray-700 hover:bg-gray-500'}`}
>
  {isSubmitting ? 'Sending...' : 'Send Reset Link'}
</button>

        </form>
        {message && <p className="mt-4 text-center text-green-500">{message}</p>}
      </div>
    </div>
  );
};

export default ResetPasswordRequest;
