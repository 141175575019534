import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Header from './Header';
import InsideHeader from './InsideHeader';
import { IoIosAddCircle } from "react-icons/io";
import { FaClipboardList } from "react-icons/fa";
import AnotherHeader from './AnotherHeader';
import { BsPencilSquare } from 'react-icons/bs';
import { BsTrash } from 'react-icons/bs';
import { FaCamera } from 'react-icons/fa';
import { FaCheckCircle } from 'react-icons/fa'; 


const MyProducts = () => {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({
    name: '',
    price: '',
    description: '',
    picture: null,
    status: '',
    category: ''
  });
  const [editProductId, setEditProductId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [error, setError] = useState(null);
  const [notice, setNotice] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileName, setFileName] = useState('Select Image');
  const [isVisible, setIsVisible] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [showWaiiona, setShowWaiiona] = useState(true); // State to show "Waiiona"




  const handleClose = () => {
    setIsVisible(false);
    setIsAdded(false);
    setIsEdited(false);
  };

  const fetchProducts = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://mybackend-new.onrender.com/my-product', {
        headers: { Authorization: token }
      });
      setProducts(response.data);
    } catch (err) {
      console.error('Error fetching products:', err);
    }
  };

  const fetchMessages = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://mybackend-new.onrender.com/messages', {
        headers: { Authorization: token }
      });
      setMessages(response.data);
      setLoadingMessages(false);
    } catch (err) {
      console.error('Error fetching messages:', err);
      setLoadingMessages(false);
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchMessages();
    const timer = setTimeout(() => {
      setShowWaiiona(false);
    }, 1000);
    return () => clearTimeout(timer); // Clean up the timer
  }, []);

  const handleChange = (e) => {



    const { name, value, files } = e.target;


    if (name === 'picture') {
      setProduct({ ...product, [name]: files[0] });
    } else {
      setProduct({ ...product, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true); 
    const token = localStorage.getItem('token');
    const formData = new FormData();


    const noCommas =  product.price.replace(/,/g, '');
    
    formData.append('name', product.name);
    formData.append('price', noCommas);
    formData.append('description', product.description);
    formData.append('status', product.status);
    formData.append('category', product.category);
    
    if (product.picture) {
      formData.append('picture', product.picture);
    }

    try {
      if (editProductId) {
        await axios.put(`https://mybackend-new.onrender.com/my-product/${editProductId}`, formData, {
          headers: {
            'Authorization': token,
            'Content-Type': 'multipart/form-data'
          }
        });
      
        setEditProductId(null);
        
        const audio = new Audio('./sound.wav'); // Provide the correct path or URL to the sound
        audio.play();
        setIsEdited(true);
       
        
      } else {
        await axios.post('https://mybackend-new.onrender.com/my-product', formData, {
          headers: {
            'Authorization': token,
            'Content-Type': 'multipart/form-data'
          }
        });

        const audio = new Audio('./sound.wav'); // Provide the correct path or URL to the sound
        audio.play();
        setIsAdded(true);
        


      }
      fetchProducts();
      setProduct({
        name: '',
        price: '',
        description: '',
        picture: null,
        status: '',
        category: ''
      });
    } catch (err) {
      console.error('Error submitting product:', err);
    }
    finally {
      setIsSubmitting(false); // Reset the loading state
    }
  };

  const handleEdit = (product) => {
    setEditProductId(product.id);
    setProduct(product);
  };



  const showNotice = (message) => {
    setNotice(message);
    
    // Set a timeout to clear the notice after 3 seconds
    setTimeout(() => {
      setNotice('');
    }, 3000); // 3000 milliseconds = 3 seconds
  };




  

  const toggleDescription = (productId) => {
    setExpandedDescriptions(prevState => ({
      ...prevState,
      [productId]: !prevState[productId]
    }));
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem('token');
    const isConfirmed = window.confirm('Are you sure you want to delete the product from the market?');
    if(isConfirmed)  {

      try {
        await axios.delete(`https://mybackend-new.onrender.com/my-product/${id}`, {
          headers: { Authorization: token }
        });
        fetchProducts();
        setIsVisible(true);
        const audio = new Audio('./sound.wav'); // Provide the correct path or URL to the sound
        audio.play();
      } catch (err) {
        console.error('Error deleting product:', err);
      }

    };
   
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
      setIsOpen(!isOpen);
    };


    const toggleImage = (image) => {
      console.log("Image clicked");
      setSelectedImage(selectedImage === image ? null : image);
    };
  










    const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        setFileName(file.name); // Set the file name to the state
      }
      handleChange(event); // Call the provided handleChange function
    };



  return (
    <div className="flex flex-col min-h-screen">
      <AnotherHeader/>
      <InsideHeader  isOpen={isOpen} toggleSidebar={toggleSidebar} />

      {showWaiiona && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-200 z-50">
    <img
      src="/logo.png"
      alt="Waiiona Logo"
      className="w-32 h-32 border-4 border-gray-300 rounded-2xl animate-pulse"
    />
  </div>
)}
      <div className="flex flex-1">
      
        <main className="flex-grow p-4 pt-20">
          <div id="add-product" className="mb-8">
        
        
          <h2 className="flex items-center justify-center text-center mb-4">
  <IoIosAddCircle className="text-2xl mr-2 font-semibold" />
  {editProductId ? 'Edit Product' : 'Post Product'}
      </h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <input
                type="text"
                name="name"
                value={product.name}
                placeholder="Name of the product"
                onChange={handleChange}
                className="text-sm w-full px-3 py-2 border-b border-gray-300"
                maxlength="30" 
                required
              />
<div className="mb-6 flex items-center">
  <span className="px-3 py-2 text-sm font-medium bg-gray-100 border-b border-gray-300">
    MK
  </span>
  <input
    type="text" // Use text to handle custom formatting
    name="price"
    value={product.price}
    placeholder="Enter product price"
    onChange={(e) => {
      const input = e.target.value.replace(/,/g, ''); // Remove commas for validation
      if (/^(?:[1-9]\d*)?$/.test(input) || input === '') {
        const formattedValue = input.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas
        handleChange({ target: { name: e.target.name, value: formattedValue } }); // Update state
      }
    }}
    maxLength="12" // Limit total characters (9 digits + commas)
    className="text-sm w-full px-3 py-2 border-b border-gray-300"
    required
  />
</div>


              <textarea
                type="text"
                name="description"
                value={product.description}
                placeholder="Description of the product"
                onChange={handleChange}
                className="text-sm w-full px-3 py-2 border-b border-gray-300"
                maxlength="200" 
                required
              />
                        <div className="w-full p-1 flex items-center">
      <label
        htmlFor="picture"
        className="cursor-pointer flex items-center text-gray-600 text-sm w-full px-3 py-1 border-b border-gray-300"
      >
        <FaCamera size={24} className="mb-1 mr-2 " />
        <span>{fileName}</span>
        <input
          id="picture"
          type="file"
          name="picture"
          accept="image/*"
          onChange={handleFileChange} // Use the local handleFileChange function
          className="hidden"
        />
      </label>
    </div>
              <select
                type="text"
                name="status"
                value={product.status}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-xl"
                required
              >
                <option value="">Select Status</option>
                <option value="Available">Available</option>
                <option value="Sold Out">Sold Out</option>
              </select>

              
              <select
                type="text"
                name="category"
                value={product.category}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-xl"
                required
              >
                <option value="">Select Category</option>
                <option value="clothes">Clothes</option>
                <option value="phones_laptops">Phones & Laptops</option>
                <option value="kitchen">Kitchen</option>
                <option value="electronics">Electronics</option>
                <option value="drug_store">Drug Store</option>
                <option value="lotion_saloon">Lotion and Saloon</option>
                <option value="farming">Farming</option>
                <option value="cars_automobile">Cars & Automobile</option>
                <option value="sports">Sports</option>
                <option value="infrastructures">Infrastructures</option>
                <option value="food">Food</option>
                <option value="books_stationary">Books and Stationary</option>
                <option value="other">Other</option>
            
              </select>
              
              <button 
  type="submit" 
  disabled={isSubmitting} 
  className={`w-full p-2 rounded-xl text-white ${isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-gray-700'}`}
>
  {isSubmitting 
    ? 'Submitting...' 
    : editProductId 
      ? 'Update Product' 
      : 'Add Product'
  }
</button>
              {notice && <p className="text-green-500 text-center mt-4">{notice}</p>}
            </form>
          </div>

        
          {isEdited && (
             <div className="fixed inset-0 flex items-center justify-center z-60">
              <div className="p-5 bg-white border  rounded-lg flex flex-col items-center w-80">
                <p className='text-black font-semibold text-md text-center ' >Product Edited!</p>
                <p className="text-black text-sm mb-2 text-center">The product has been edited on the market.</p>
                <FaCheckCircle className="text-green-600 text-5xl mb-4" />
                <button
                  className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-green-600"
                  onClick={handleClose}
                >
                  OK
                </button>
              </div>
              </div> 
            )}

          {isAdded && (
             <div className="fixed inset-0 flex items-center justify-center z-60">
              <div className="p-5 bg-white border  rounded-lg flex flex-col items-center w-80">
                <p className='text-black font-semibold text-md text-center ' >Product Posted!</p>
                <p className="text-black text-sm mb-2 text-center">The product has been posted on the market.</p>
                <FaCheckCircle className="text-green-600 text-5xl mb-4" />
                <button
                  className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-green-600"
                  onClick={handleClose}
                >
                  OK
                </button>
              </div>
              </div> 
            )}


           {isVisible && (
             <div className="fixed inset-0 flex items-center justify-center z-60">
              <div className="p-5 bg-white border  rounded-lg flex flex-col items-center w-80">
                <p className='text-black font-semibold text-md text-center ' >Product Deleted!</p>
                <p className="text-black text-sm mb-2 text-center">The product has been deleted on the market.</p>
                <FaCheckCircle className="text-red-600 text-5xl mb-4" />
                <button
                  className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-green-600"
                  onClick={handleClose}
                >
                  OK
                </button>
              </div>
              </div> 
            )}
          <div id="product-list">

          <div className="flex items-center justify-center mb-4">
  <FaClipboardList className="text-2xl mr-2" />
  <h2 className="text-2xl font-bold text-center">Your Sells List</h2>
</div>
            <h2 className="text-xs font-bold mb-4 text-center">Note: Kindly delete your products after they are sold. Thank you!</h2>
           
            <ul className=" grid grid-cols-1 md:grid-cols-4 gap-1 rounded-xl">
              {products.map(product => (
                <li key={product.id} className="p-4 border border-gray-300 rounded-xl">
                 
                  {product.picture && (
                    <img 
                    src={`${product.picture}`} 
                    alt={product.name} 
                    onClick={() => toggleImage(`${product.picture}`)} 
                    className="w-full h-60 object-cover rounded-lg" />
                  )}
                   <h3 className="text-xl font-bold">{product.name}</h3>
                  <p>Price: K{product.price}</p>
                  <div className="text-gray-700 text-sm max-h-24 overflow-hidden">
                      {expandedDescriptions[product.id] || product.description.length <= 10
                        ? product.description
                        : `${product.description.substring(0, 8)}...`}
                      {product.description.length > 5 && (
                        <span
                          onClick={() => toggleDescription(product.id)}
                          className="text-blue-500 text-xs cursor-pointer"
                        >
                          {expandedDescriptions[product.id] ? ' Show Less' : 'Read More'}
                        </span>
                      )}
                    </div>
                  <p>Status: {product.status}</p>
                  <div className="flex space-x-4 mt-2">
                  <button
  onClick={() => {
    handleEdit(product);
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Ensures smooth scrolling to the top
    });
  }}
  className="p-2 bg-gray-700 text-white rounded-xl flex items-center"
>
  <BsPencilSquare className="mr-2" />
  Edit
</button>

         <button
          onClick={() => handleDelete(product.id)}
             className="p-2 bg-red-800 text-white rounded-xl flex items-center"
             >
               <BsTrash className="mr-1" />
                   Delete
                     </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </main>
       
      </div>

      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative">
            <img
              src={selectedImage}
              alt="Product"
              className="max-w-full max-h-full rounded-3xl"
              onClick={() => setSelectedImage(null)}
            />
            <button
              onClick={() => setSelectedImage(null)}
              className="absolute top-0 right-0 p-2 text-white bg-black rounded-full"
            >
              &times;
            </button>
          </div>
        </div>
      )}
      <br/><br/>
    </div>
  );
};

export default MyProducts;
