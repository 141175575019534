import React, { useState } from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'; 

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');
  const [notice, setNotice] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  
  const showNotice = (message) => {
    setNotice(message);
    
    // Set a timeout to clear the notice after 3 seconds
    setTimeout(() => {
      setNotice('');
    }, 3000); // 3000 milliseconds = 3 seconds
  };




  
  const showNoticeUp = (message) => {
    setMessage(message);
    
    // Set a timeout to clear the notice after 3 seconds
    setTimeout(() => {
      setMessage('');
      navigate('/Login');

    }, 3000); // 3000 milliseconds = 3 seconds
  };



  const handleSubmit = async (e) => {
    e.preventDefault();


    if (isSubmitting) return;
    setIsSubmitting(true);

    // Basic client-side validation
    if (newPassword.trim() === '') {
      setMessage('Password cannot be empty');
      return;
    }
    
    const passwordRegex = /^(?=.*[A-Z]).{8,}$/;

    if (!passwordRegex.test(newPassword)) {
        showNotice('Oops!, Password must be at least 8 characters long and contain at least one capital letter.');
        return;
    }


    setLoading(true);

    try {
      const response = await axios.post('https://mybackend-new.onrender.com/api/users/reset-password', { token, newPassword });
      showNoticeUp('Password changed successfully. Redirecting you to Login page...');
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.message);
      } else {
        setMessage('An unexpected error occurred');
      }
    } finally {
      setIsSubmitting(false); // Reset the loading state
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4 bg-gray-100">
      <div className="w-full max-w-md bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-4 text-center">Reset Password</h2>
        <form onSubmit={handleSubmit} className="space-y-4 relative">
          <input
             type={showPassword ? 'text' : 'password'}
            placeholder="Enter new password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute right-3 top-1 transform -translate-y-1/2 text-gray-500"
        >
          {showPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
          </button>

{/* Notice message */}
{notice && <p className="text-red-500 text-center mt-4">{notice}</p>}

{/* Reset Password button with isSubmitting */}
<button
  type="submit"
  disabled={isSubmitting || loading} 
  className="w-full py-3 bg-gray-800 text-white font-semibold rounded-lg hover:bg-blue-600 disabled:bg-gray-400 cursor-not-allowed"
>
  {(isSubmitting || loading) ? 'Resetting...' : 'Reset Password'}
</button>

        

        </form>
        {message && <p className="mt-4 text-center text-green-500">{message}</p>}
      </div>
    </div>
  );
};

export default ResetPassword;
