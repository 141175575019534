import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Header from './Header';
import InsideHeader from './InsideHeader';
import { IoIosAddCircle } from "react-icons/io";
import { FaClipboardList } from "react-icons/fa";
import { FaHouseUser } from "react-icons/fa";
import AnotherHeader from './AnotherHeader';
import { BsPencilSquare } from 'react-icons/bs';
import { BsTrash } from 'react-icons/bs';
import { FaCamera } from 'react-icons/fa';
import { FaCheckCircle } from 'react-icons/fa'; 
import { FaSpinner} from "react-icons/fa";



const PostHouse = () => {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({
    name: '',
    price: '',
    description: '',
    picture: null,
    status: '',
    location: ''
  });
  const [editProductId, setEditProductId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [notice, setNotice] = useState('');
  const [fileName, setFileName] = useState('Select Image');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [showWaiiona, setShowWaiiona] = useState(true); // State to show "Waiiona"
   const [isAdded, setIsAdded] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

  
  
  
    const handleClose = () => {
      setIsVisible(false);
      setIsEdited(false);
      setIsAdded(false);
      setIsProcessing(false);
    };


  const fetchProducts = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://mybackend-new.onrender.com/my-house', {
        headers: { Authorization: token }
      });
      setProducts(response.data);
    } catch (err) {
      console.error('Error fetching products:', err);
    }
  };

  const fetchMessages = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://mybackend-new.onrender.com/messages', {
        headers: { Authorization: token }
      });
      setMessages(response.data);
      setLoadingMessages(false);
    } catch (err) {
      console.error('Error fetching messages:', err);
      setLoadingMessages(false);
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchMessages();
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'picture') {
      setProduct({ ...product, [name]: files[0] });
    } else {
      setProduct({ ...product, [name]: value });
    }
  };


  const toggleDescription = (productId) => {
    setExpandedDescriptions(prevState => ({
      ...prevState,
      [productId]: !prevState[productId]
    }));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true); 

    setIsProcessing(true);

    const token = localStorage.getItem('token');
    const formData = new FormData();
    const noCommas =  product.price.replace(/,/g, '');

    formData.append('name', product.name);
    formData.append('price', noCommas);
    formData.append('description', product.description);
    formData.append('status', product.status);
    formData.append('location', product.location);
    
    if (product.picture) {
      formData.append('picture', product.picture);
    }

    try {
      if (editProductId) {
        await axios.put(`https://mybackend-new.onrender.com/my-house/${editProductId}`, formData, {
          headers: {
            'Authorization': token,
            'Content-Type': 'multipart/form-data'
          }
        });
       
        setIsProcessing(false);
        const audio = new Audio('./sound.wav'); // Provide the correct path or URL to the sound
        audio.play();
        setIsEdited(true);
        fetchProducts();
        setEditProductId(null);
      } else {
        await axios.post('https://mybackend-new.onrender.com/my-house', formData, {
          headers: {
            'Authorization': token,
            'Content-Type': 'multipart/form-data'
          }
        });

        setIsProcessing(false);
        const audio = new Audio('./sound.wav'); // Provide the correct path or URL to the sound
        audio.play();
        setIsAdded(true);
      }
      fetchProducts();
      setProduct({
        name: '',
        price: '',
        description: '',
        picture: null,
        status: '',
        location: ''
      });
    } catch (err) {
      console.error('Error submitting product:', err);
    }
    finally {
      setIsSubmitting(false); // Reset the loading state
    }
  };

  const handleEdit = (product) => {
    setEditProductId(product.id);
    setProduct(product);
  };

  const handleDelete = async (id) => {

    const isConfirmed = window.confirm('Are you sure you want to remove your accommodation from the market?');

    if(isConfirmed){
      setIsProcessing(true);
      const token = localStorage.getItem('token');
      try {
        await axios.delete(`https://mybackend-new.onrender.com/my-house/${id}`, {
          headers: { Authorization: token }
        });

        setIsProcessing(false);
        const audio = new Audio('./sound.wav'); // Provide the correct path or URL to the sound
        audio.play();
        setIsVisible(true);
        fetchProducts();

      } catch (err) {
        console.error('Error deleting product:', err);
      }
    };

   
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };





  const showNotice = (message) => {
    setNotice(message);
    
    // Set a timeout to clear the notice after 3 seconds
    setTimeout(() => {
      setNotice('');
    }, 3000); // 3000 milliseconds = 3 seconds
  };








  


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name); // Set the file name to the state
    }
    handleChange(event); // Call the provided handleChange function
  };







  return (
    <div className="flex flex-col min-h-screen">
      <AnotherHeader/>
      <InsideHeader toggleSidebar={toggleSidebar} />


       
                {isEdited && (
                   <div className="fixed inset-0 flex items-center justify-center z-60">
                    <div className="p-5 bg-white border  rounded-lg flex flex-col items-center w-80">
                      <p className='text-black font-semibold text-md text-center ' >Accommodation Edited!</p>
                      <p className="text-black text-sm mb-2 text-center">The accommodation has been edited on the market.</p>
                      <FaCheckCircle className="text-green-600 text-5xl mb-4" />
                      <button
                        className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-green-600"
                        onClick={handleClose}
                      >
                        OK
                      </button>
                    </div>
                    </div> 
                  )}


                             {isVisible && (
                               <div className="fixed inset-0 flex items-center justify-center z-60">
                                <div className="p-5 bg-white border  rounded-lg flex flex-col items-center w-80">
                                  <p className='text-black font-semibold text-md text-center ' >Accommodation Deleted!</p>
                                  <p className="text-black text-sm mb-2 text-center">The accommodation has been deleted on the market.</p>
                                  <FaCheckCircle className="text-red-600 text-5xl mb-4" />
                                  <button
                                    className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-green-600"
                                    onClick={handleClose}
                                  >
                                    OK
                                  </button>
                                </div>
                                </div> 
                              )}

                                        {isAdded && (
                                           <div className="fixed inset-0 flex items-center justify-center z-60">
                                            <div className="p-5 bg-white border  rounded-lg flex flex-col items-center w-80">
                                              <p className='text-black font-semibold text-md text-center ' >Product Posted!</p>
                                              <p className="text-black text-sm mb-2 text-center">The product has been posted on the market.</p>
                                              <FaCheckCircle className="text-green-600 text-5xl mb-4" />
                                              <button
                                                className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-green-600"
                                                onClick={handleClose}
                                              >
                                                OK
                                              </button>
                                            </div>
                                            </div> 
                                          )}
                                             {isProcessing && (
                                            <div className="fixed inset-0 flex items-center justify-center z-60 bg-gray-800 bg-opacity-50">
                                              <div className="p-6 bg-white border rounded-lg flex flex-col items-center w-80 shadow-lg">
                                                <p className="text-black font-semibold text-lg text-center mb-2">Please wait...</p>
                                                <p className="text-gray-700 text-sm mb-4 text-center">Your request is being processed.</p>
                                                <FaSpinner className="text-gray-600 text-5xl animate-spin mb-4" />
                                                <button
                                                  className="px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-green-600 transition duration-300"
                                                  onClick={handleClose}
                                                >
                                                  OK
                                                </button>
                                              </div>
                                            </div>
                                          )}


      <div className="flex flex-1">
  
        <main className="flex-grow p-4 pt-16">
          <div id="add-product" className="mb-8">
         
          <h2 className="flex items-center justify-center text-center mb-4">
  <IoIosAddCircle className="text-2xl mr-2 font-semibold" />
  {editProductId ? 'Edit Hostel' : 'Add Hostel'}
      </h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <input
                type="text"
                name="name"
                value={product.name}
                placeholder="Name"
                onChange={handleChange}
                className="text-sm w-full px-3 py-2 border-b border-gray-300"
                maxlength="20" 
                required
              />
            <div className="mb-6 flex items-center">
  <span className="px-3 py-2 text-sm font-medium bg-gray-100 border-b border-gray-300">
    MK
  </span>
  <input
    type="text" // Use text to handle custom formatting
    name="price"
    value={product.price}
    placeholder="Enter product price"
    onChange={(e) => {
      const input = e.target.value.replace(/,/g, ''); // Remove commas for validation
      if (/^(?:[1-9]\d*)?$/.test(input) || input === '') {
        const formattedValue = input.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas
        handleChange({ target: { name: e.target.name, value: formattedValue } }); // Update state
      }
    }}
    maxLength="12" // Limit total characters (9 digits + commas)
    className="text-sm w-full px-3 py-2 border-b border-gray-300"
    required
  />
</div>
              <textarea
                type="text"
                name="description"
                value={product.description}
                placeholder="Description"
                onChange={handleChange}
                className="text-sm w-full px-3 py-2 border-b border-gray-300"
                maxlength="200" 
                required
              />
          <div className="w-full p-2 rounded-xl flex items-center">
           <label
        htmlFor="picture"
      className="cursor-pointer flex items-center text-gray-600 text-sm w-full px-3 py-1 border-b border-gray-300"
      >
        <FaCamera size={24} className="mb-2 mr-2" />
        <span>{fileName}</span>
        <input
          id="picture"
          type="file"
          name="picture"
          accept="image/*"
          onChange={handleFileChange} // Use the local handleFileChange function
          className="hidden"
        />
      </label>
    </div>
               <textarea
                type="text"
                name="location"
                value={product.location}
                placeholder="Location"
                onChange={handleChange}
                className="text-sm w-full px-3 py-2 border-b border-gray-300"
                maxlength="50" 
                required
              />
              <select
                type="text"
                name="status"
                value={product.status}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-xl"
                required
              >
                <option value="">Select Status</option>
                <option value="Available">Available</option>
                <option value="Unavailable">Not available</option>
              </select>
             
             

              <button 
  type="submit" 
  disabled={isSubmitting} 
  className={`w-full p-2 rounded-xl text-white ${isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-gray-700'}`}
>
  {isSubmitting 
    ? 'Submitting...' 
    : editProductId 
      ? 'Update Hostel' 
      : 'Add Hostel'
  }
</button>



              {notice && <p className="text-green-500 text-center mt-4">{notice}</p>}
            </form>
          </div>
          <div id="product-list">
           
          <div className="flex items-center justify-center mb-4">
  <FaHouseUser  className="text-2xl mr-2" />
  <h2 className="text-2xl font-bold text-center">Your Accomodation List</h2>
</div>
            <h2 className="text-xs font-bold mb-4 text-center">Kindly delete your accommodation after all negotiation are made. Thank you!</h2>
            <ul className="grid grid-cols-1 md:grid-cols-4 gap-1">
              {products.map(product => (
                <li key={product.id} className="p-4 border border-gray-300 rounded-xl">
              
                
                  {product.picture && (
                    <img src={`${product.picture}`} alt={product.name} className="w-full h-60 object-cover rounded-lg" />
                  )}
                      <h3 className="text-xl font-bold">{product.name}</h3>
                    <p>Price: K{product.price}</p>
                    <div className="text-gray-700 text-sm max-h-24 overflow-hidden">
                      {expandedDescriptions[product.id] || product.description.length <= 10
                        ? product.description
                        : `${product.description.substring(0, 8)}...`}
                      {product.description.length > 5 && (
                        <span
                          onClick={() => toggleDescription(product.id)}
                          className="text-blue-500 text-xs cursor-pointer"
                        >
                          {expandedDescriptions[product.id] ? ' Show Less' : 'Read More'}
                        </span>
                      )}
                    </div>
                  <p>Status: {product.status}</p>
                  <p>Location: {product.location}</p>
                  <div className="flex space-x-4 mt-2">
                  <button
   onClick={() => {
    handleEdit(product);
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Ensures smooth scrolling to the top
    });
  }}
  className="p-2 bg-gray-600 text-white rounded-xl flex items-center"
>
  <BsPencilSquare className="mr-2" />
  Edit
</button>
<button
  onClick={() => handleDelete(product.id)}
  className="p-2 bg-red-800 text-white rounded-xl flex items-center"
>
  <BsTrash className="mr-1" />
  Delete
</button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </main>
      </div>
      <br/><br/>
    </div>
  );
};

export default PostHouse;
