import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NewHeader from './NewHeader';
import { useSearchParams } from 'react-router-dom';
import { MdCancel } from "react-icons/md";
import { ImProfile } from "react-icons/im";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from 'react-router-dom';
import { FaSearch } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import { FaFilter } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import { IoSearch } from "react-icons/io5";

const MarketPage = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [showPersonModal, setShowPersonModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(searchParams.get('search') || ''); // Initialize searchTerm from URL
  const [selectedCategory, setSelectedCategory] = useState(''); // State for selected category
  const [isUserNavOpen, setIsUserNavOpen] = useState(false); // State to handle user icon dropdown toggle
  const [currentPage, setCurrentPage] = useState(1); // State for pagination
  const [productsPerPage] = useState(20); // Number of products per page

  const location = useLocation();
  const currentPath = location.pathname;
  const [isNavOpen, setIsNavOpen] = useState(false); // State to handle sidebar toggle
  const [showWaiiona, setShowWaiiona] = useState(false); // State to show "Waiiona"
  

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen); // Toggle sidebar visibility
  };

  const userNavItems = [
    { id: 'clothes', label: 'Clothes' },
    { id: 'phones_laptops', label: 'Phones & Laptops' },
    { id: 'electronics', label: 'Electronics' },
    { id: 'kitchen', label: 'Kitchen' },
    { id: 'drug_store', label: 'Drug Store' },
    { id: 'lotion_saloon', label: 'Lotion and Saloon' },
    { id: 'farming', label: 'Farming' },
    { id: 'cars_automobile', label: 'Cars & Automobile' },
    { id: 'sports', label: 'Sports' },
    { id: 'infrastructures', label: 'Infrastructures' },
    { id: 'food', label: 'Food' },
    { id: 'books_stationary', label: 'Books and Stationary' },
    { id: 'other', label: 'Other' },
  ];

  const toggleUserNav = () => {
    setIsUserNavOpen(!isUserNavOpen); // Toggle user dropdown visibility
  };

  useEffect(() => {
    fetchProducts();
    const timer = setTimeout(() => {
      setShowWaiiona(false);
    }, 1000);
    return () => clearTimeout(timer); // Clean up the timer
   
  }, [searchTerm, selectedCategory, currentPage]); // Refetch products whenever searchTerm, selectedCategory, or currentPage changes

  const fetchProducts = async () => {
    console.log(selectedCategory);
    try {
      const res = await axios.get(`https://mybackend-new.onrender.com/products`, {
        params: {
          search: searchTerm,
          category: selectedCategory, // Pass selected category to the API
        },
      });
      const shuffledProducts = shuffleArray(res.data); // Shuffle products randomly
      setProducts(shuffledProducts);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching products:', err);
      setLoading(false);
    }
  };

  // Fisher-Yates shuffle algorithm to randomize the order of products
  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    setSearchParams({ search: newSearchTerm }); // Update the URL with the new search term
  };

  // Handle category selection
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setIsUserNavOpen(false); // Close dropdown on category selection
  };

  const toggleImage = (image) => {
    setSelectedImage(selectedImage === image ? null : image);
  };

  // Filter products based on search term
  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.owner_username.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.location.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Get the current products for the page
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  // Handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  const togglePersonModal = (product) => {
    setCurrentProduct(product);
    setShowPersonModal(!showPersonModal);
  };

  const refresh = () =>  { window.location.reload();}


 

  return (
    <div>
      <NewHeader />
      <div className="relative min-h-screen flex">
      {showWaiiona && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-200 z-50">
    <img
      src="/logo.png"
      alt="Waiiona Logo"
      className="w-32 h-32 border-4 border-gray-300 rounded-2xl animate-pulse"
    />
  </div>
)}

        {showPersonModal && currentProduct && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="border bg-white border-gray-300 w-full max-w-lg h-full overflow-auto">
            <div className='flex p-2 fixed bg-white w-full items-center justify-between'>
  <button onClick={togglePersonModal}>
    <IoClose size={24} />
  </button>
  
  <div className='flex-grow flex justify-center'>
    <span className='text-center font-semibold'>{currentProduct.owner_username}'s post</span>
  </div>
</div>

              <div className='w-full'>
                {currentProduct.picture && (
                  <img
                    src={`${currentProduct.picture}`}
                    alt={currentProduct.name}
                    onClick={() => toggleImage(`${currentProduct.picture}`)}
                    className="w-full mt-12 h-80 object-cover rounded-lg border-2 border-gray"
                  />
                )}
              </div>
              <div className="p-2">
                <p className='text-lg font-bold'>{currentProduct.name}</p>
                <p className='text-2xs mb-2 text-gray-700 text-sm mb-2 max-h-24 overflow-hidden'>{currentProduct.description}</p>
                <p className='text-2xs text-green-500'>MK{Math.round(currentProduct.price).toLocaleString()}</p>
                <p className='text-2xs'><span className='font-bold' >Status: </span>{currentProduct.status}</p>
                <div className="flex items-center justify-center mt-4">
                  <CgProfile size={20} className="mr-1" />
                  <h2 className="text-md font-bold text-center">Our Business Profile</h2>
                </div>
                <hr className="border-t-2 border-gray-300 mb-4 mt-2" />
                <p className='mb-2 text-gray-700 text-center text-sm mb-2 mt-2 max-h-24'>{currentProduct.userDescription}</p>
                <hr className="border-t-2 border-gray-300 mb-4 mt-2" />
                <p className='text-2xs'><span className='font-bold' >Seller:</span> {currentProduct.owner_username}</p>
                <p className='text-2xs flex'><span className='font-bold mt-1 mr-2' ><FaLocationDot/> </span>{currentProduct.location}</p>
              
              </div>
              <div className="w-full flex items-center p-2 justify-center ">
  <Link to="/Login" className="w-full">
    <button className="w-full text-gray-800 text-md font-semibold p-3 bg-gray-300 rounded-lg cursor-pointer hover:bg-gray-400 transition-all duration-300">
      Add to cart
    </button>
  </Link>
</div>

            </div>
            
          </div>
        )}
        <div className="flex-1 p-1 flex">
          {loading ? (
                  <div className="fixed inset-0 flex items-center justify-center bg-gray-200 z-40">
                  <div className="flex flex-col items-center justify-center">
           <img
             src="/logo.png"
             alt="Waiiona Logo"
             className="w-32 h-32 border-4 border-gray-300 rounded-2xl animate-pulse"
           />
         
         </div>
         
                    </div>
          ) : (
            <div className="flex-1 p-0 rounded-6xl pt-14">
              <div className="bg-white">
                <div className="flex">
                <div className="mb-4 flex w-full mr-4 ml-2 relative">
  <input
    type="text"
    placeholder="Search by name, description, or owner"
    value={searchTerm}
    onChange={handleSearchChange} // Capture input changes
    className="p-2 pl-10 border border-gray-300 w-full rounded-xl"
  />
  <IoSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" size={20} />
</div>
                  <div className='relative ml-auto mt-1 mr-4 text-gray-600'>
                    <button onClick={toggleUserNav} className="focus:outline-none">
                      <FaFilter size={23} />
                    </button>
                  </div>
                  {isUserNavOpen && (
                    <div className="absolute right-0 mt-6 w-48 bg-white shadow-lg rounded-lg py-2 z-50">
                      <span className='ml-2 text-sm font-semibold'>Select Category</span>
                      <h1 onClick={refresh} className='flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left'>All</h1>
                      {userNavItems.map(item => (
                        <button
                          key={item.id}
                          onClick={() => handleCategorySelect(item.id)} // Update category on click
                          className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                        >
                          {item.label}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
                <div className="grid grid-cols-2 md:grid-cols-5 gap-2 p-1">
  {currentProducts.map(product => (
    <div
      key={product.id}
      className="bg-white rounded-2xl shadow-lg hover:shadow-2xl transform hover:-translate-y-1 transition-all duration-300 mb-2"
    >
      <img
        src={product.picture}
        alt={product.name}
        onClick={() => togglePersonModal(product)}
        className="w-full h-44 object-cover rounded-t-2xl mb-2 cursor-pointer hover:opacity-90"
      />
      <div className="px-2 pb-2 text-center">
        <p className="text-sm font-medium text-gray-700">{product.name}</p>
      </div>
    </div>
  ))}
</div>

                <div className="flex justify-center items-center mt-4">
                  <button
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="p-2 bg-gray-300 rounded-lg disabled:opacity-50 mr-2"
                  >
                    <MdKeyboardDoubleArrowLeft />
                  </button>
                  <span>Page {currentPage} of {totalPages}</span>
                  <button
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="p-2 bg-gray-300 rounded-lg disabled:opacity-50 ml-2"
                  >
                    <MdKeyboardDoubleArrowRight />
                  </button>
                </div>
              </div>
            </div>
          )}



{selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative">
            <img
              src={selectedImage}
              alt="Product"
              className="max-w-full max-h-full rounded-xl"
              onClick={() => setSelectedImage(null)}
            />
            <button
              onClick={() => setSelectedImage(null)}
              className="absolute top-0 right-0 p-2 text-white bg-black rounded-full"
            >
              &times;
            </button>
          </div>
        </div>
      )}

        </div>
      </div>
    </div>
  );
};

export default MarketPage;
