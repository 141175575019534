import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import InsideHeader from './InsideHeader';
import { UserContext } from './UserContext';
import { GrConnect } from "react-icons/gr";
import AnotherHeader from './AnotherHeader';
import { MdCancel } from "react-icons/md";
import { MdOutlineConnectWithoutContact } from "react-icons/md";
import { IoMdChatbubbles, IoMdCall } from "react-icons/io";
import { BsWhatsapp } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { FaSpinner} from "react-icons/fa";
import { FaCheckCircle } from 'react-icons/fa'; 

const Notification = () => {
  const { user } = useContext(UserContext);
  const [products, setProducts] = useState([]);
  const [summary, setSummary] = useState({ product_count: 0, total_price: 0 });
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [loading, setLoading] = useState(true);
  const [showWaiiona, setShowWaiiona] = useState(true); // State to show "Waiiona"
  const [isVisible, setIsVisible] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  

  const fetchProducts = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://mybackend-new.onrender.com/my-notice', {
        headers: { Authorization: token }
      });
      setLoading(false);
      setProducts(response.data.cart || []);

      // Ensure summary has default values if not present
      setSummary(response.data.summary || { product_count: 0, total_price: 0 });

    } catch (err) {
      console.error('Error fetching products:', err);
    }
  };

  useEffect(() => {
    fetchProducts();
    const timer = setTimeout(() => {
      setShowWaiiona(false);
    }, 1000);
    return () => clearTimeout(timer); // Clean up the timer
  }, []);

  const handleDelete = async (id, buyer) => {
    setIsProcessing(true);
    const isConfirmed = window.confirm('Are you sure you want to delete your ordered product?');
    if (isConfirmed) {
      setIsProcessing(true);
      try {
        await axios.delete(`https://mybackend-new.onrender.com/my-notice/${id}?buyer=${buyer}`);
        const audio = new Audio('./sound.wav'); // Provide the correct path or URL to the sound
                audio.play();
        setIsProcessing(false);
        fetchProducts();
        setIsVisible(true);
     
      } catch (err) {
        console.error('Error deleting product:', err);
      }
    }
  };


  const toggleDescription = (productId) => {
    setExpandedDescriptions(prevState => ({
      ...prevState,
      [productId]: !prevState[productId]
    }));
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
      setIsOpen(!isOpen);
    };



    const handleClose = () => {
      setIsVisible(false);
      setIsProcessing(false);
  
    };
  

  return (
    <div className="flex flex-col min-h-screen">
      <AnotherHeader/>
      <InsideHeader isOpen={isOpen} toggleSidebar={toggleSidebar} />

      {showWaiiona && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-200 z-50">
    <img
      src="/logo.png"
      alt="Waiiona Logo"
      className="w-32 h-32 border-4 border-gray-300 rounded-2xl animate-pulse"
    />
  </div>
)}


{isProcessing && (
  <div className="fixed inset-0 flex items-center justify-center z-[9999] bg-gray-800 bg-opacity-50">
    <div className="p-6 bg-white border rounded-lg flex flex-col items-center w-80 shadow-2xl">
      <p className="text-black font-semibold text-lg text-center mb-2">Please wait...</p>
      <p className="text-gray-700 text-sm mb-4 text-center">Your request is being processed.</p>
      <FaSpinner className="text-gray-600 text-5xl animate-spin mb-4" />
      <button
        className="px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-green-600 transition duration-300"
        onClick={handleClose}
      >
        OK
      </button>
    </div>
  </div>
)}


           {isVisible && (
             <div className="fixed inset-0 flex items-center justify-center z-100">
              <div className="p-5 bg-white border  rounded-lg flex flex-col items-center w-80">
                <p className='text-black font-semibold text-md text-center ' >Product Deleted!</p>
                <p className="text-black text-sm mb-2 text-center">The product has been deleted in your cart.</p>
                <FaCheckCircle className="text-red-600 text-5xl mb-4" />
                <button
                  className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-green-600"
                  onClick={handleClose}
                >
                  OK
                </button>
              </div>
              </div> 
            )}



      <div className="flex flex-1">
        
        <main className="flex-grow p-4 pt-20">
          <div id="product-list" > 
            <h2 className="text-2xl font-bold mb-4 text-center">My Requests</h2>
            <div className="mb-4">
            <p className="flex items-center px-6 py-3 bg-gray-700 text-white rounded-xl shadow mb-2">
                  Total Order Requests: {summary.product_count}
                </p>
            </div>
            {loading ? (<div className="fle flex-col items-center justify-center fixed inset-0 flex items-center justify-center z-60">
  <svg
    className="animate-spin h-10 w-10 text-blue-600 mb-4"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8v8H4z"
    ></path>
  </svg>
  <p className="text-lg font-semibold text-gray-700 animate-pulse">...Loading Requests</p>
</div>) : (
           <ul className="grid grid-cols-1 md:grid-cols-2 gap-4 p-2">
           {products.length > 0 ? (
             products.map((product, index) => (
               <li
                 key={`${product.id}-${index}`}
                 className="p-4 border bg-white shadow-md rounded-2xl hover:shadow-lg  order-last"
               >
                 <div className="flex items-center justify-center mb-4">
                   <MdOutlineConnectWithoutContact className="text-2xl text-blue-500 mr-2" />
                   <h2 className="text-lg font-bold text-center">Request From: {product.username}</h2>
                 </div>
         
                 <h3 className="text-xl font-semibold text-gray-800">{product.name}</h3>
                 <p className="text-gray-600">Price: K{product.price}</p>
         
                 {/* Expandable description */}
                 <div className="text-gray-700 text-sm max-h-24 overflow-hidden mt-2">
                   {expandedDescriptions[product.id] || product.description.length <= 35
                     ? product.description
                     : `${product.description.substring(0, 8)}...`}
                   {product.description.length > 30 && (
                     <span
                       onClick={() => toggleDescription(product.id)}
                       className="text-blue-500 text-xs cursor-pointer hover:underline"
                     >
                       {expandedDescriptions[product.id] ? ' Show Less' : 'Read More'}
                     </span>
                   )}
                 </div>
         
                 {product.picture && (
                   <img
                     src={`${product.picture}`}
                     alt={product.name}
                     className="w-full h-60 object-cover rounded-lg mt-2"
                   />
                 )}
         
                 <p className="text-xs text-gray-500 mt-2">Status: {product.status}</p>
                 <p className="text-xs text-gray-500">{product.phoneNumber}</p>
                 <p className="text-xs text-gray-500">{new Date(product.order_date).toLocaleString()}</p>
         
                 {/* Action Buttons */}
                 <div className="flex flex-wrap justify-between mt-4 gap-2">
                  
         
                   <div className="flex flex-wrap gap-2 w-full md:w-auto bg-gray-700 text-white px-4 py-2 rounded-xl">
                     <span>Connect</span>
         
                     <button className="p-1 bg-gray-600 text-white rounded-full hover:bg-gray-500">
                      <a
                         href={`https://wa.me/${product.phoneNumber}?text=Hello%20${product.username}%20from%20Waiiona%20Online%20Market,%20I%20have%20seen%20your%20request%20on%20${product.name}%20I%20posted%20on%20Waiiona%20Market.%20Please%20let's%20discuss.%20https://waiiona.store`}
                         target="_blank"
                         rel="noopener noreferrer"
                       >
                         <BsWhatsapp size={15} />
                       </a>
                     </button>
         
                     <button className="p-1 bg-gray-600 text-white rounded-full hover:bg-gray-500">
                       <a href={`tel:${product.phoneNumber}`}>
                         <IoMdCall size={15} />
                       </a>
                     </button>
         
                     <button className="p-1 bg-gray-600 text-white rounded-full hover:bg-gray-500">
                       <a href={`mailto:${product.email}`}>
                         <MdEmail size={15} />
                       </a>
                     </button>
                   </div>
                   <button
                     onClick={() => handleDelete(product.product_id, product.buyer_id)}
                     className="w-full md:w-auto px-4 py-2 bg-red-600 text-white rounded-xl hover:bg-red-700 transition-all"
                   >
                     <MdCancel className="inline-block mr-1" />
                     Reject
                   </button>
                 </div>
               </li>
             ))
           ) : (
             <p className="text-center text-gray-600">No products requested yet!</p>
           )}
         </ul>
         
         
            )}
          </div>
        </main>
      </div>
      <br/><br/>
    </div>
  );
};

export default Notification;
