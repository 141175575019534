import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaHouseUser } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Header from './Header';
import { FaHouse } from "react-icons/fa6";
import NewHeader from './NewHeader';
import { FaSearch } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { CgProfile } from "react-icons/cg";
import { IoClose } from "react-icons/io5";

function RentHostel() {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showPersonModal, setShowPersonModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);


  useEffect(() => {
    fetchProducts();
  }, []);

  
  const fetchProducts = async () => {
    try {
      const res = await axios.get(`https://mybackend-new.onrender.com/houses?search=${searchTerm}`);

      const shuffledProducts = shuffleArray(res.data);
      
      setProducts(shuffledProducts);

      setLoading(false);
    } catch (err) {
      console.error('Error fetching products:', err);
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };


  const toggleDescription = (productId) => {
    setExpandedDescriptions(prevState => ({
      ...prevState,
      [productId]: !prevState[productId]
    }));
  };

  const getTimeDifference = (timestamp) => {
    const now = new Date();
    const createdAt = new Date(timestamp);
    const differenceInSeconds = Math.floor((now - createdAt) / 1000);

    const days = Math.floor(differenceInSeconds / (3600 * 24));
    const hours = Math.floor((differenceInSeconds % (3600 * 24)) / 3600);
    const min = Math.floor((differenceInSeconds % 3600) / 60);
    const seconds = differenceInSeconds % 60;

    if (days > 0) {
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hr${hours !== 1 ? 's' : ''} ago`;
    } else if (min > 0) {
      return `${min} min${min !== 1 ? 's' : ''} ago`;
    } else {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    }
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.owner_username.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.location.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const togglePersonModal = (product) => {
    setCurrentProduct(product);
    setShowPersonModal(!showPersonModal);
  };


   
  const toggleImage = (image) => {
    console.log("Image clicked");
    setSelectedImage(selectedImage === image ? null : image);
  };




  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };
  


  return (
    <div>
      <NewHeader/>
      <div className="relative min-h-screen flex flex-col">
        <div className="flex-1 p-1 mt-14">
          {loading ? (
             <div className="fixed inset-0 flex items-center justify-center bg-gray-200 z-50">
         <div className="flex flex-col items-center justify-center">
  <img
    src="/logo.png"
    alt="Waiiona Logo"
    className="w-32 h-32 border-4 border-gray-300 rounded-2xl animate-pulse"
  />
 
</div>

           </div>
          ) : (
            <div className="">
              
                        <div className="mb-4 flex w-full  relative">
               <input
                 type="text"
                 placeholder="Search by name, description, or owner"
                 value={searchTerm}
                 onChange={handleSearchChange} // Capture input changes
                 className="p-2 pl-10 border border-gray-300 w-full rounded-xl"
               />
               <IoSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" size={20} />
             </div>
              <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-1">
                {filteredProducts.map(product => (
                  <div key={product.id} className="border p- rounded-md shadow">
                    <img
                      src={`${product.picture}`}
                      alt={product.name}
                      onClick={() => togglePersonModal(product)}
                      className="w-full h-44 object-cover mb-2 rounded-sm"
                    />
                    <div className='pl-1 pr-1' >
                    <h2 className="text-md font-bold">{product.name}</h2>
                 
                    <div className="flex items-center mt-2 mb-2">
    
                        <button 
                        onClick={() => togglePersonModal(product)}
                        className="text-gray-800 text-xs font-semibold bg-gray-300 rounded p-1 cursor-pointer">
                          Book Now
                        </button>

                    </div>
              
                  </div>
                </div>  
                ))}
              </div>
            </div>
          )}
        </div>

      
         {showPersonModal && currentProduct && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="border bg-white border-gray-300 w-full max-w-lg h-full overflow-auto">
                            <div className='flex p-2 fixed bg-white w-full items-center justify-between'>
                      <button onClick={togglePersonModal}>
                        <IoClose size={24} />
                      </button>
                      
                      <div className='flex-grow flex justify-center'>
                        <span className='text-center font-semibold'>{currentProduct.owner_username}'s post</span>
                      </div>
                    </div>
                      <div className='w-full'>
                        {currentProduct.picture && (
                          <img
                            src={`${currentProduct.picture}`}
                            alt={currentProduct.name}
                            onClick={() => toggleImage(`${currentProduct.picture}`)}
                            className="w-full mt-12 h-80 object-cover rounded-lg border-2 border-gray"
                          />
                        )}
                      </div>
                      <div className="p-2">
                        <p className='text-lg font-bold'>{currentProduct.name}</p>
                        <p className='text-2xs mb-2 text-gray-700 text-sm mb-2 max-h-24 overflow-hidden'>{currentProduct.description}</p>
                        <p className='text-2xs text-green-500'>MK{Math.round(currentProduct.price).toLocaleString()}</p>
                        <p className='text-2xs'><span className='font-bold' >Status: </span>{currentProduct.status}</p>
                        <p className='text-2xs'><span className='font-bold' >Owner:</span> {currentProduct.owner_username}</p>
                        <p className='text-2xs flex'><span className='font-bold mt-1 mr-2' ><FaLocationDot/> </span>{currentProduct.location}</p>
                      
                         
                        <div className="flex items-center justify-center ">
                     
                        </div>
                      
                      </div>
                      <div className="w-full flex items-center p-2 justify-center ">
          <Link to="/Login" className="w-full">
            <button className="w-full text-gray-800 text-md font-semibold p-3 bg-gray-300 rounded-lg cursor-pointer hover:bg-gray-400 transition-all duration-300">
              Book Now
            </button>
          </Link>
        </div>
        
                    </div>
                    
                  </div>
                )}

      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative">
            <img
              src={selectedImage}
              alt="Product"
              className="max-w-full max-h-full rounded-3xl"
              onClick={() => setSelectedImage(null)}
            />
            <button
              onClick={() => setSelectedImage(null)}
              className="absolute top-0 right-0 p-2 text-white bg-black rounded-full"
            >
              &times;
            </button>
          </div>
        </div>
      )}



      </div>
    </div>
  );
}

export default RentHostel;
