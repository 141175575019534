import React from 'react';
import Header from './Header';
import Background from './Background';
import { GiReceiveMoney } from "react-icons/gi";
import { FaShoppingCart, FaBed, FaHeadset } from "react-icons/fa";
import './Home.css'; // Import the CSS file
import { Link } from 'react-router-dom';
import { FaLeaf } from 'react-icons/fa';
import { FaClipboardList } from "react-icons/fa6";
import { FaHouseChimneyWindow } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import NewHeader from './NewHeader';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { GiTakeMyMoney } from "react-icons/gi";
import { CiShoppingCart } from "react-icons/ci";
import { useNavigate } from 'react-router-dom'; 
import Footer from './Footer';
import { BiSolidMessageRounded } from "react-icons/bi";
import {FaStore } from 'react-icons/fa';  // Other icons
import { GiHouse } from "react-icons/gi";
import { BiSolidMessageDetail } from "react-icons/bi";

const videoList = [
  'https://th.bing.com/th?id=OIF.KgppQwruZu9XeeVktD5%2fnw&w=192&h=192&c=7&r=0&o=5&pid=1.7',
  'https://th.bing.com/th?id=OIF.77EX70Ev%2fMWGvscYeWecNw&w=162&h=180&c=7&r=0&o=5&pid=1.7',
  'https://th.bing.com/th?id=OIF.a54gXgHWJ4sqfLGdk%2bOV0Q&w=169&h=180&c=7&r=0&o=5&pid=1.7',
  'https://th.bing.com/th?id=OIF.X%2bHXI0KmOql9Jw5DoooNDg&w=189&h=189&c=7&r=0&o=5&pid=1.7',
  'https://th.bing.com/th?id=OIF.BPazz3mL%2bTxT3zQe5Py%2b1g&w=177&h=180&c=7&r=0&o=5&pid=1.7',
];


const carouselSettings = {

  
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  rtl: true,
};



const carouselSetting = {

  
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 2,
  autoplay: true,
  autoplaySpeed: 2000,
};



function Home() {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate(); 
  const [notice, setNotice] = useState('');
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageContent, setMessageContent] = useState('');
  const [showWaiiona, setShowWaiiona] = useState(true); // State to show "Waiiona"


  
   
  useEffect(() => {

    fetchProducts();
    const timer = setTimeout(() => {
      setShowWaiiona(false);
    }, 1000);
    return () => clearTimeout(timer); // Clean up the timer
   
  }, []);

  const fetchProducts = async () => {
    try {
      const res = await axios.get(`https://mybackend-new.onrender.com/products?search=${searchTerm}`);
      
      // Shuffle products to appear in random order
      const shuffledProducts = shuffleArray(res.data);
      
      setProducts(shuffledProducts);
     
    } catch (err) {
      console.error('Error fetching products:', err);
      
    }
  };


    
  const handleSendMessage = async () => {


      if(messageContent) {
    showNotice('Message sent');
   
try {

 await axios.post(
   'https://mybackend-new.onrender.com/feedback',
   {
     description: messageContent
   }
 
 );


 setMessageContent('');

 setTimeout(() => setNotice(''), 3000);
 
 setShowMessageModal(false);
} catch (err) {
 console.error('Error sending message:', err);
}

   }

   else{
    setShowMessageModal(false);
   }
};





const showNotice = (message) => {
  setNotice(message);
  
  // Set a timeout to clear the notice after 3 seconds
  setTimeout(() => {
    setNotice('');
  }, 5000); // 3000 milliseconds = 3 seconds
};




   // Fisher-Yates shuffle algorithm to randomize the order of products
  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };
  


  const filteredProducts = products.filter(product =>
    product.name
  );


  const handleSearchSubmit = (e) => {
    e.preventDefault();
    navigate(`/marketPage?search=${searchTerm}`); // Redirect to Market with search term
  };







  return (
    <div>
      
      <NewHeader/>
      <div className="relative min-h-screen">
        <Background />
        {showWaiiona && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-200 z-50">
    <img
      src="/logo.png"
      alt="Waiiona Logo"
      className="w-32 h-32 border-4 border-gray-300 rounded-2xl animate-pulse"
    />
  </div>
)}
        <div className="relative z-10 min-h-screen flex flex-col pt-10 py-8 ">
        
        <div className='ml-4 mt-8 mb-5' >
        <h1 className="header text-3xl font-bold  text-white mb-2 ">
              GO SMART BUYING
            </h1>
       



            <p  className='header text-md text-white ml-1' >ONLINE MARKETING MADE EASY</p>

     </div>
   
      <form onSubmit={handleSearchSubmit} className="relative w-74 max-w-md ml-4 mb-4 mr-4">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          required
          placeholder="What are you looking for?"
          className="w-full h-12 pl-4 pr-20 text-gray-700 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:ring focus:border-blue-300"
        />
        <button  type="submit" className="absolute top-0 right-0 h-full px-6 text-white bg-gray-500 rounded-r-full  pr-4 hover:bg-gray-400">
          Search
        </button>
      </form>
  

              <div className='text-white text-xs  ml-8 ' >
                   <li className='mb-2' >Vast product variety</li>
                   <li  className='mb-2'>Making shopping easier</li>
                   <li className='mb-2'>Book your accommodation at ease</li>

              </div>
                  
               <Link to="/Login" >
                  <button className='flex p-1 items-center justify-center ml-5 mt-6 text-white  pl-2 pr-2 border-white border-2 w-40 rounded-2xl'>
                      join free
                       <IoIosArrowForward size={18} className=" ml-2 " />
                  </button>
                  </Link>

          <div className='text-white items-center justify-center flex gap-4 mt-10' >
              
              <div className='flex' >

                  <FaClipboardList className='mt-1' />Order
              </div>
              
               <div className='flex' >

                  <GiTakeMyMoney className='mt-1' />Pay
                 </div>
                <div className='flex' >

                   < CiShoppingCart size={18} className='mt-1 ' />Collect
                 </div>

          </div>



    
          <div className="flex items-center justify-center mt-8 mx-8">
      <Link to="/marketPage">
        <div className="border border-gray-400 rounded-xl mb-4 p-5 flex items-center gap-3 bg-gradient-to-r from-gray-700 to-gray-900 hover:shadow-xl hover:scale-105 transition-all duration-300">
          <FaStore className="text-white text-2xl" /> {/* Store icon */}
          <p className="text-white text-lg font-semibold">Explore Our Market</p>
        </div>
      </Link>
    </div>


                        
    <Slider {...carouselSetting} className="grid grid-cols-2 md:grid-cols-5 gap-2 mt-4 mb-6 ml-1 mr-1 rounded-md bg-white ">


  {/* Render the filtered products list */}
  {filteredProducts.map((product) => (
    <div key={product.id} className="rounded-md">
      <img
        src={`${product.picture}`}
        alt={product.name}
        className="w-full h-40 object-cover "
      />

        <h2 className="text-xs font-bold">{product.name}</h2>
        
        <p className="text-xs font-semibold">MK{product.price}</p>
  
    </div>
  ))}

  </Slider> 
      
         {showMessageModal && (
        <div className="fixed inset-0 p-4 bg-black bg-opacity-50 flex items-center justify-center z-50 ">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-md rounded-3xl">
            <h2 className="text-xl mb-4">Send a feedback</h2>
            <div className="flex flex-col">
              <textarea
                value={messageContent}
                onChange={(e) => setMessageContent(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                rows="2"
                placeholder="Type your feedback here..."
                maxlength="200"
                required
              ></textarea>
            </div>

              <div className='flex' >
              <button
                onClick={handleSendMessage}
                className="bg-gray-800 text-white p-2 rounded-md mt-2 mr-2"
              >
                Send
              </button>
           
            <button
              onClick={() => setShowMessageModal(false)}
              className="bg-red-800 text-white p-2 rounded-md mt-2 w-16"
            >
              Cancel
            </button>
         </div>
         {notice && <p className="text-green-500 mt-2 text-center">{notice}</p>}
         
          </div>
        
        </div>
      )}

         


          <button   onClick={() => {
                      
                        setShowMessageModal(true);
                      }} className="fixed bottom-4 right-1 text-red-500  rounded-full shadow-lg hover:bg-blue-400 focus:outline-none z-50">
            <BiSolidMessageDetail size={46} /> <span className='text-xs text-white' >Feedback</span>
          </button>
            

       <div>
          <p className="header text-center text-md text-white  mt-6">
              Discover the best products and enjoy the best services we offer. We are committed to providing top-notch customer satisfaction and quality products.
            </p>
            </div>

            <hr className="border-t-2 border-white mb-2 mt-8" />


               

                 <h1  className='mt-10 font-bold text-white ml-5 text-center' > Trending Products</h1>
                
  <Slider {...carouselSettings} className="grid grid-cols-2 md:grid-cols-5 gap- mt-4 mb-6 ml-1 mr-1 rounded- bg-white ">


{/* Render the filtered products list */}
{filteredProducts.map((product) => (
  <div key={product.id} className="rounded  rounded-md">
    <img
      src={`${product.picture}`}
      alt={product.name}
      className="w-full h-40 object-cover m rounded-sm"
    />

      <h2 className="text-xs font-bold">{product.name}</h2>
      
      <p className="text-xs font-semibold">MK{product.price}</p>
 
  </div>
))}

</Slider>

        </div>
      </div>
    </div>
  );
}

export default Home;
