import React, { useState } from 'react';
import Header from './Header';
import axios from 'axios';
import Background from './Background';
import { useEffect } from 'react';
import Login from './Login';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'; 
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import NewHeader from './NewHeader';
import { FaCheckCircle } from 'react-icons/fa'; 
import { Link } from 'react-router-dom';

const SignUp = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [dob, setDob] = useState('');
    const [gender, setGender] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [text, setText] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [notice, setNotice] = useState('');
    const [otp, setOtp] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
  
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };


    const showText = (message) => {
        setText(message);
        
        // Set a timeout to clear the notice after 3 seconds
        setTimeout(() => {
          setText('');
        }, 10000); // 3000 milliseconds = 3 seconds
      };






    const showNotice = (message) => {
        setNotice(message);
        
        // Set a timeout to clear the notice after 3 seconds
        setTimeout(() => {
          setNotice('');

        }, 3000); // 3000 milliseconds = 3 seconds
      };


     
     
   

      const showNoticeUp = (message) => {
        setMessage(message);
        
        // Set a timeout to clear the notice after 3 seconds
        setTimeout(() => {
          setMessage('');
          navigate('/Login');

        }, 3000); // 3000 milliseconds = 3 seconds
      };


    const handleSubmit = async (e) => {
        e.preventDefault();


        const passwordRegex = /^(?=.*[A-Z]).{8,}$/;

        if (!passwordRegex.test(password)) {
            showNotice('Oops!, Password must be at least 8 characters long and contain at least one capital letter.');
            return;
        }

        if (isSubmitting) return;
        setIsSubmitting(true);

        const Number = "+265"+phoneNumber;
        
        console.log(phoneNumber);
        try {
           
              

                    const response = await axios.post('https://mybackend-new.onrender.com/send-otp', {
                        username,
                        password,
                        email,
                        gender,
                        phoneNumber: Number,
                    });

                    if (response.data.success) {
                        setIsOtpSent(true);
                        const audio = new Audio('./sound.wav'); // Provide the correct path or URL to the sound
                        audio.play();
                        setMessage('OTP sent to your email. Please enter it above.');
                    } else {
                        setMessage('Failed to send OTP. Please try again.');
                    }
                
            
        } catch (error) {
            console.error('Sign up failed:', error);
            setMessage('Sign up failed. Please try again.');
        }
        finally {
            setIsSubmitting(false); // Reset the loading state
          }
    };




    const handleOtpSubmit = async (e) => {
        e.preventDefault();   

          const phoneWithoutSpaces = "+265"+phoneNumber;

          if (isSubmitting) return;
          setIsSubmitting(true);


        try {
            const response = await axios.post('https://mybackend-new.onrender.com/verify-otp', {
                email,
                otp,
                username,
                password,
                gender,
                phoneWithoutSpaces
            });

            if (response.data.success) {

              
                const audio = new Audio('./sound.wav'); // Provide the correct path or URL to the sound
                audio.play();
                setIsVisible(true);


               

            } else {
                showNoticeUp('Invalid OTP. Please try again.');
            }
        } catch (error) {
            console.error('OTP verification failed:', error);
            setMessage('The email already exist. Please use another email.');
        }
        finally {
            setIsSubmitting(false); // Reset the loading state
          }
    };

    return (
        <div className="relative min-h-screen">
           
            <div className="relative z-10">
               
                <NewHeader/>
                  {isVisible && (
                   <div className="fixed inset-0 flex items-center justify-center z-60">
                    <div className="p-5 bg-white border  rounded-lg flex flex-col items-center w-80">
                      <p className='text-black font-semibold text-md text-center ' >Congratulations!</p>
                      <p className="text-black text-sm mb-2 text-center">User signed up successfully, Enjoy!</p>
                      <FaCheckCircle className="text-green-600 text-5xl mb-4" />

                 <Link to="/Login" >
                      <button
                        className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-green-600"
                      >
                        OK
                      </button>
                </Link>
                    </div>
                    </div> 
                  )}

                <div className="flex  justify-center bg-opacity-0 bg-gray-100 pt-10">
                    <div className="max-w-md w-full p-6 bg-daisy-200 rounded-3xl shadow-lg  m-3 ">
                        <h2 className="text-3xl font-bold mb-1 pt-0 text-center">Sign Up</h2>
                        <h5 className='text-center text-xs mb-4'>Welcome! Sign up to enjoy our services.</h5>
                        {!isOtpSent ? (
                            <form onSubmit={handleSubmit}>
                                <div className="mb-6">
                                   
                                    <input
                                        type="text"
                                        id="username"
                                        name="username"
                                        value={username}
                                        placeholder='Enter Username'
                                        onChange={(e) => setUsername(e.target.value)}
                                        className="text-sm w-full px-2 py-2 border-b border-gray-300"
                                        required
                                    />
                                </div>
                                <div className="mb-2">
                                   

                                    <div className="mb-6 relative">
                                    <input
        type={showPassword ? 'text' : 'password'}
        id="password"
        name="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder='Enter Password'
        className="text-sm w-full px-3 py-2 border-b border-gray-300 pr-10" // Added pr-10 to make room for the icon inside the input
        required
      />
     
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"
        >
          {showPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
        </button>
        </div>
      
                                </div>
                               
                                {notice && <p className="text-red-500 text-center mb-2 text-sm mt-4">{notice}</p>}
                                <div className="mb-6">
                                    
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={email}
                                        placeholder='Enter a valid Email'
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="text-sm w-full px-3 py-2 border-b border-gray-300"
                                        required
                                    />
                                </div>
                                <div className="mb-6">
    <div className="flex items-center">
        {/* Prefix for the phone number */}
        <div className="flex items-center bg-gray-100 p-1">
    <span className="text-xl">🇲🇼</span>
    <span className="ml-">+265</span>
</div>
        {/* Input field for the remaining phone number */}
        <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            placeholder="Enter Phone Number e.g. 992889346"
            value={phoneNumber}
            onChange={(e) => {
                const input = e.target.value;
                // Validate input: allow only 9 digits and ensure it doesn't start with 0
                if (/^[1-9][0-9]{0,8}$/.test(input) || input === '') {
                    setPhoneNumber(input); // Update state only if input is valid
                }
            }}
            className="text-sm w-full px-3 py-2 border-b border-gray-300"
            required
        />
    </div>
    <small className="text-gray-500">Phone number should be 9 digits and not start with 0.</small>
</div>

                               
                                <div className="mb-4">
                                
                                    <select
                                        id="gender"
                                        name="gender"
                                        value={gender}
                                        onChange={(e) => setGender(e.target.value)}
                                        className="w-full px-3 py-1 border-b border-gray-300 "
                                        required
                                    >
                                        <option value="">Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                                <button
  type="submit"
  disabled={isSubmitting}
  className={`w-full bg-gray-600 text-white py-2 px-4 rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-500 transition-all duration-300 ${
    isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-gray-600'
  }`}
>
  {isSubmitting ? (
    <span className="flex justify-center items-center">
      <svg
        className="animate-spin h-5 w-5 mr-2 text-white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <circle cx="12" cy="12" r="10" strokeWidth="4" />
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M4 12a8 8 0 0116 0"
        />
      </svg>
      Signing Up...
    </span>
  ) : (
    'Sign Up'
  )}
</button>

                            </form>
                        ) : (
                            <form onSubmit={handleOtpSubmit}>
                                <div className="mb-4">
                                    <label htmlFor="otp" className="block text-sm font-medium text-gray-700">
                                        Enter OTP
                                    </label>
                                    <input
                                        type="text"
                                        id="otp"
                                        name="otp"
                                        value={otp}
                                        onChange={(e) => setOtp(e.target.value)}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                        required
                                    />
                                </div>
                                <button
  type="submit"
  disabled={isSubmitting}
  className={`w-full bg-gray-700 text-white py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 hover:bg-blue-600 transition-all duration-300 ${
    isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-gray-700'
  }`}
>
  {isSubmitting ? (
    <span className="flex justify-center items-center">
      <svg
        className="animate-spin h-5 w-5 mr-2 text-white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
      >
        <circle cx="12" cy="12" r="10" strokeWidth="4" />
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M4 12a8 8 0 0116 0"
        />
      </svg>
      Verifying OTP...
    </span>
  ) : (
    'Verify OTP'
  )}
</button>

                            </form>
                        )}
                        {message && <p className="text-green-500 text-sm text-center mt-4">{message}</p>}
                        {text && <p className="text-red-500 text-center mt-4">{text}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
