import React from 'react';
import { FaBullhorn, FaChartLine, FaUsers } from 'react-icons/fa';
import InsideHeader from './InsideHeader';
import AnotherHeader from './AnotherHeader';
import { FaBagShopping } from "react-icons/fa6";
import { MdOutlineRealEstateAgent } from "react-icons/md";
import Header from './Header';
import NewHeader from './NewHeader';
import { useState, useEffect } from 'react';

const AboutUsOut = () => {

   const [showWaiiona, setShowWaiiona] = useState(true); // State to show "Waiiona"


    useEffect(() => {
   
       const timer = setTimeout(() => {
        setShowWaiiona(false);
      }, 1000);
      return () => clearTimeout(timer); // Clean up the timer
     
     }, []);
  return (
    <div>
    
    <NewHeader/>
    {showWaiiona && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-200 z-50">
    <img
      src="/logo.png"
      alt="Waiiona Logo"
      className="w-32 h-32 border-4 border-gray-300 rounded-2xl animate-pulse"
    />
  </div>
)}
    <div className="bg-white text-gray-800 p-8 max-w-4xl mx-auto rounded-lg shadow-lg mt-14">
      <h1 className="text-xl font-bold text-center mb-3">Waiiona Online Marketing Company</h1>
      
      <p className="text-lg leading-relaxed mb-6 text-center">
        We are online marketing company dedicated to helping businesses of all sizes 
        grow and thrive in the digital landscape. Our team of experts specializes in a wide range 
        of services to boost your brand's online presence, increase customer engagement, and drive sales.
      </p>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">


      <div className="flex flex-col items-center">
          <FaBagShopping className="text-blue-500 text-6xl mb-4" />
          <h3 className="text-xl font-semibold mb-2">Buying & Selling Services</h3>
          <p className="text-center text-gray-600">
            We provide a powerful Waiiona service which gives an opportunity to all sorts of business scales hence reducing the gap among sellers and buyers. 
            We use a secure and reliable source to satisfy our customers.
          </p>
        </div>


        <div className="flex flex-col items-center">
          <MdOutlineRealEstateAgent className="text-green-500 text-6xl mb-4" />
          <h3 className="text-xl font-semibold mb-2">Accommodation Services</h3>
          <p className="text-center text-gray-600">
           Through the waiona online marketing platforms we also provide accommodation rental services. Our goal is to make sure we reduce the gap between hostel or house owners and students.
           Through this platforms not only student's accommodation are considered but also for anyone who would like to use the Platform.
          </p>
        </div>
    

        <div className="flex flex-col items-center">
          <FaBullhorn className="text-blue-500 text-6xl mb-4" />
          <h3 className="text-xl font-semibold mb-2">Digital Advertising</h3>
          <p className="text-center text-gray-600">
            Reach your target audience with our powerful digital advertising strategies. 
            We use data-driven methods to ensure your ads are seen by the right people at the right time.
          </p>
        </div>
        <div className="flex flex-col items-center">
          <FaChartLine className="text-green-500 text-6xl mb-4" />
          <h3 className="text-xl font-semibold mb-2">SEO Optimization</h3>
          <p className="text-center text-gray-600">
            Improve your website’s visibility on search engines with our expert SEO services. 
            We help you rank higher and attract more organic traffic.
          </p>
        </div>
        <div className="flex flex-col items-center">
          <FaUsers className="text-purple-500 text-6xl mb-4" />
          <h3 className="text-xl font-semibold mb-2">Social Media Management</h3>
          <p className="text-center text-gray-600">
            Engage with your customers on social media platforms. We create compelling content 
            and manage your online presence to build and maintain your brand’s reputation.
          </p>
        </div>
      </div>
    </div>
    <br/><br/>
    </div>
  );
};

export default AboutUsOut;
