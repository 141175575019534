import React, { useState, useEffect } from 'react';
import axios from 'axios';


import { AiFillLike, AiFillMessage } from "react-icons/ai";
import { FaShoppingCart } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import InsideHeader from './InsideHeader';
import { MdEmail } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { IoMdPerson } from "react-icons/io";
import { FaHouseUser } from "react-icons/fa";
import AnotherHeader from './AnotherHeader';
import { BsWhatsapp } from "react-icons/bs";
import {IoMdCall } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import { FaHouse } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";


function RentHouse() {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageContent, setMessageContent] = useState('');
  const [currentProductOwner, setCurrentProductOwner] = useState(null);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [loading, setLoading] = useState(true);
  const [newProduct, setNewProduct] = useState({
    name: '',
    price: '',
    description: '',
    status: ''
  });
  const [file, setFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showPersonModal, setShowPersonModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const res = await axios.get(`https://mybackend-new.onrender.com/houses?search=${searchTerm}`);
      const shuffledProducts = shuffleArray(res.data);
      
      setProducts(shuffledProducts);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching products:', err);
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleMessageClick = (ownerId) => {
    setCurrentProductOwner(ownerId);
    setShowMessageModal(true);
  };

  const handleSendMessage = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`https://mybackend-new.onrender.com/products/messages`, {
        ownerId: currentProductOwner,
        content: messageContent
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setShowMessageModal(false);
      setMessageContent('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleOrderClick = async (productId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`https://mybackend-new.onrender.com/products/orders`, {
        productId
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        alert('Order placed successfully!');
      }
    } catch (error) {
      console.error('Error placing order:', error);
    }
  };

  const toggleDescription = (productId) => {
    setExpandedDescriptions(prevState => ({
      ...prevState,
      [productId]: !prevState[productId]
    }));
  };

  const getTimeDifference = (timestamp) => {
    const now = new Date();
    const createdAt = new Date(timestamp);
    const differenceInSeconds = Math.floor((now - createdAt) / 1000);

    const days = Math.floor(differenceInSeconds / (3600 * 24));
    const hours = Math.floor((differenceInSeconds % (3600 * 24)) / 3600);
    const min = Math.floor((differenceInSeconds % 3600) / 60);
    const seconds = differenceInSeconds % 60;

    if (days > 0) {
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hr${hours !== 1 ? 's' : ''} ago`;
    } else if (min > 0) {
      return `${min} min${min !== 1 ? 's' : ''} ago`;
    } else {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    }
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.owner_username.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.location.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const togglePersonModal = (product) => {
    setCurrentProduct(product);
    setShowPersonModal(!showPersonModal);
  };





  const toggleImage = (image) => {
    console.log("Image clicked");
    setSelectedImage(selectedImage === image ? null : image);
  };




  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  return (
    <div>
      <AnotherHeader/>
      <InsideHeader isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className="relative min-h-screen flex">
        <div className="relative  flex">
       

          {loading ? (           <div className="fixed inset-0 flex items-center justify-center bg-gray-200 z-50">
         <div className="flex flex-col items-center justify-center">
  <img
    src="/logo.png"
    alt="Waiiona Logo"
    className="w-32 h-32 border-4 border-gray-300 rounded-2xl animate-pulse"
  />
 
</div>

           </div>
          ) : (
          <div className="flex-1 p-0 rounded mt-20">
            <div className=" p-1 bg-white">
           
                       <div className="mb-4 flex w-full  relative">
                            <input
                              type="text"
                              placeholder="Search by name, description, or owner"
                              value={searchTerm}
                              onChange={handleSearchChange} // Capture input changes
                              className="p-2 pl-10 border border-gray-300 w-full rounded-xl"
                            />
                            <IoSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" size={20} />
                          </div>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-1">
                {filteredProducts.map(product => (
                  <div key={product.id} className="border p-1 rounded-md shadow">
                    <img src={`${product.picture}`} alt={product.name}       onClick={() => togglePersonModal(product)}  className="w-full h-44 object-cover mb-2 rounded-md border" />
                   
                   
                   <div className='pl-1 pr-1' >
                    <h2 className="text-md font-bold">{product.name}</h2>
          
                    <div className="flex items-center mt-2">
                      <button
                       onClick={() => togglePersonModal(product)}
                        className="text-gray-800 text-xs font-semibold mr p-0 border-none bg-gray-300 rounded cursor-pointer"
                      >
                       <h6 className='p-1' >Book Now</h6>
                      </button>
                   
                     
                    </div>
           
                  </div>
                </div>  
                ))}
              </div>
            </div>
          </div>
          )}
        </div>
      



           
               {showPersonModal && currentProduct && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                          <div className="border bg-white border-gray-300 w-full max-w-lg h-full overflow-auto">
                                  <div className='flex p-2 fixed bg-white w-full items-center justify-between'>
                            <button onClick={togglePersonModal}>
                              <IoClose size={24} />
                            </button>
                            
                            <div className='flex-grow flex justify-center'>
                              <span className='text-center font-semibold'>{currentProduct.owner_username}'s post</span>
                            </div>
                          </div>
                            <div className='w-full'>
                              {currentProduct.picture && (
                                <img
                                  src={`${currentProduct.picture}`}
                                  alt={currentProduct.name}
                                  onClick={() => toggleImage(`${currentProduct.picture}`)}
                                  className="w-full mt-12 h-80 object-cover rounded-lg border-2 border-gray"
                                />
                              )}
                            </div>
                            <div className="p-2">
                              <p className='text-lg font-bold'>{currentProduct.name}</p>
                              <p className='text-2xs mb-2 text-gray-700 text-sm mb-2 max-h-24 overflow-hidden'>{currentProduct.description}</p>
                              <p className='text-2xs text-green-500'>MK{Math.round(currentProduct.price).toLocaleString()}</p>
                              <p className='text-2xs'><span className='font-bold' >Status: </span>{currentProduct.status}</p>
                              <p className='text-2xs flex'><span className='font-bold mt-1 mr-2' ><FaLocationDot/> </span>{currentProduct.location}</p>
                            
                              <hr className="border-t-2 border-gray-300 mb-3 mt-3 mt-2" />  
                              <div className="flex items-center justify-center mb-2">
                        
             <IoMdPerson  className="text-2xl mr-2" />
              <h2 className="text-xl font-bold text-center">Person Information</h2>
               </div>
              <h5  className='text-xs pb-2 font-semibold text-center' >Use the information provided below to contact the owner to book the place</h5>
              <p className='text-2xs'><span className='font-semibold'  >Owner</span> : {currentProduct.owner_username}</p>
              <p className='text-2xs'><span className='font-semibold'  >Phone </span>   : {currentProduct.phoneNumber}</p>

              <p className='text-2xs'><span className='font-semibold'  >Email </span>   : {currentProduct.email}</p>
                              <div className="flex items-center justify-center ">
                           
                              </div>
                            
                            </div>
                            <div className="flex items-center justify-center space-x-2 bg-gray-600 text-white p-2 rounded-xl mx-2 mb-2">
  <span>Connect</span>
  <button className="p-1 bg-gray-700 text-white rounded-full">
    <a
      href={`https://wa.me/${currentProduct.phoneNumber}?text=Hello%20${currentProduct.owner_username}%20from%20Waiiona%20Online%20Market,%20I%20am%20interested%20in%20your%20${currentProduct.name}%20you%20posted%20on%20Waiiona%20accommodation%20platform.%20https://waiiona.store`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <BsWhatsapp size={15} />
    </a>
  </button>

  <button className="p-1 bg-gray-700 text-white rounded-full">
    <a href={`tel:${currentProduct.phoneNumber}`}>
      <IoMdCall size={15} />
    </a>
  </button>
  
  <button className="p-1 bg-gray-700 text-white rounded-full">
    <a href={`mailto:${currentProduct.email}`}>
      <MdEmail size={15} />
    </a>
  </button>
</div>

              
                          </div>
                          
                        </div>
                      )}
     
      </div>


      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative">
            <img
              src={selectedImage}
              alt="Product"
              className="max-w-full max-h-full rounded-xl"
              onClick={() => setSelectedImage(null)}
            />
            <button
              onClick={() => setSelectedImage(null)}
              className="absolute top-0 right-0 p-2 text-white bg-black rounded-full"
            >
              &times;
            </button>
          </div>
        </div>
      )}
      <br/><br/><br/>
    </div>
  );
}

export default RentHouse;
