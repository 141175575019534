import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from './UserContext';

import InsideHeader from './InsideHeader';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import AnotherHeader from './AnotherHeader';
import { BsPerson } from 'react-icons/bs';
import { FaUserEdit } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'; 
import { FaSpinner} from "react-icons/fa";

const Welcome = () => {
  const { user, setUser } = useContext(UserContext);
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [notice, setNotice] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showWaiiona, setShowWaiiona] = useState(false); // State to show "Waiiona"
  const [isProcessing, setIsProcessing] = useState(false);




    useEffect(() => {
      const timer = setTimeout(() => {
        setShowWaiiona(false);
      }, 1000);
      return () => clearTimeout(timer); // Clean up the timer
    }, []);



 


    const handleClose = () => {
      setIsProcessing(false);
    };

 

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          navigate('/Login'); // Redirect to login if no token
          return;
        }

        const response = await axios.get('https://mybackend-new.onrender.com/user', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setUser(response.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch user data');
        navigate('/Login'); // Redirect to login if fetching user data fails
      }
    };

    fetchUserData();
  }, [setUser, navigate]);





  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    if (name === 'phoneNumber') {
      // Only allow up to 9 digits and ensure it doesn't start with 0
      if (/^[1-9][0-9]{0,8}$/.test(value) || value === '') {
        setUser(prevState => ({
          ...prevState,
          [name]: `+265${value}` // Always store with the prefix
        }));
      }
    } else {
      setUser(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };
  






  const showNotice = (message) => {
    setNotice(message);
    
    // Set a timeout to clear the notice after 3 seconds
    setTimeout(() => {
      setNotice('');
    }, 10000); // 3000 milliseconds = 3 seconds
  };








  const handleFormSubmit = async (e) => {
    e.preventDefault();

  console.log(user.password);
   if(user.password){
    const passwordRegex = /^(?=.*[A-Z]).{8,}$/;
    if (!passwordRegex.test(user.password)) {
        showNotice('Oops!, Password must be at least 8 characters long and contain at least one capital letter.');
        return;
    }

    
    try {
      const token = localStorage.getItem('token');
      await axios.put(`https://mybackend-new.onrender.com/users/${user.id}`, user, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setIsEditing(false);
      window.location.reload();
      const audio = new Audio('./sound.wav'); // Provide the correct path or URL to the sound
      audio.play();
    } catch (error) {
      setError('Failed to update user data');
    }}   



    else{
      try {
        const token = localStorage.getItem('token');
        await axios.put(`https://mybackend-new.onrender.com/users/${user.id}`, user, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setIsEditing(false);
        const audio = new Audio('./sound.wav'); // Provide the correct path or URL to the sound
        audio.play();
      } catch (error) {
        setError('Failed to update user data');
      }} 

    }


 






    const handleSubmit = async (e) => {
      e.preventDefault();

      setIsProcessing(true);
      console.log(email);
    
      try {
        const response = await axios.post('https://mybackend-new.onrender.com/send-otp-email', {
          email
        });
    
        if (response.data.success) {

          setIsProcessing(false);
          setIsOtpSent(true);
          const audio = new Audio('./sound.wav'); // Provide the correct path or URL to the sound
          audio.play();
          setMessage('OTP sent to your email. Please enter it above.');
        } else {
          setMessage('Failed to send OTP. Please try again.');
        }
      } catch (error) {
        setMessage('An error occurred. Please try again.');
      }
    };
    







    const handleOtpSubmit = async (e) => {
      e.preventDefault();


    const userId = user.id;
      console.log(email);
      console.log(userId);
      console.log(otp);
    
      try {
        const response = await axios.put(
          `https://mybackend-new.onrender.com/verify-otp-email/${userId}`,
          {
            email,
            otp,
          }
        );
    
        if (response.data.success) {
          setMessage('Email changed successfully.');
          const audio = new Audio('./sound.wav'); // Provide the correct path or URL to the sound
          audio.play();
          window.location.reload();

        } else {
          setMessage('Invalid OTP. Please try again.');
        }
      } catch (error) {
        console.error('OTP verification failed:', error);
        setMessage('The email already exists. Please use another email.');
      }
    };
    




















    const [showModal, setShowModal] = useState(false);
    const [email, setEmail] = useState('');
  
    const handleButtonClick = () => {
      setShowModal(true);
    };
  
    const handleCloseModal = () => {
      setShowModal(false);
      
    };




    const handleCloseModall = () => {
      setShowModal(false);
      window.location.reload();
      
    };


  
    const handleSave = () => {
      // Add your logic to save the email
      setShowModal(false);
    };




    

  
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  if (error) {
    return <div>{error}</div>;
  }


  const phone = user?.phoneNumber;
const trimmedPhone = phone?.substring(4) || '';
console.log(trimmedPhone);




  // Formatting the date
  const dateString = user?.dob;
  const date = new Date(dateString);
  const day = date.getUTCDate();
  const month = monthNames[date.getUTCMonth()]; // getUTCMonth() returns 0-based month index
  const year = date.getUTCFullYear();
  const formattedDate = `${month} ${day}, ${year}`;

  const isValidPassword = (password) => {
    return password.length >= 8 && /[A-Z]/.test(password) && /\d/.test(password);
  };
  

  return (

    <div>
       <AnotherHeader />
      <InsideHeader isOpen={isOpen} toggleSidebar={toggleSidebar} />

      {showWaiiona && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-200 z-50">
    <img
      src="/logo.png"
      alt="Waiiona Logo"
      className="w-32 h-32 border-4 border-gray-300 rounded-2xl animate-pulse"
    />
  </div>
)}
    {isProcessing && (
  <div className="fixed inset-0 flex items-center justify-center z-[9999] bg-gray-800 bg-opacity-50">
    <div className="p-6 bg-white border rounded-lg flex flex-col items-center w-80 shadow-lg">
      <p className="text-black font-semibold text-lg text-center mb-2">Please wait...</p>
      <p className="text-gray-700 text-sm mb-4 text-center">Your request is being processed.</p>
      <FaSpinner className="text-gray-600 text-5xl animate-spin mb-4" />
      <button
        className="px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-green-600 transition duration-300"
        onClick={handleClose}
      >
        OK
      </button>
    </div>
  </div>
)}


       {loading ? (<div className="fixed inset-0 flex items-center justify-center bg-gray-200 z-50">
         <div className="flex flex-col items-center justify-center">
  <img
    src="/logo.png"
    alt="Waiiona Logo"
    className="w-32 h-32 border-4 border-gray-300 rounded-2xl animate-pulse"
  />
 
</div>

           </div>
      
    ) :(
    <div className="flex flex-col">
     

    <div>

      {showModal && (     
        <div className="fixed inset-0 bg-gray-800 z-40 bg-opacity-75 flex items-center justify-center p-3">
           {!isOtpSent ? (
  
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Update Email</h2>
          <form onSubmit={handleSubmit}>
          <label className="block text-sm font-medium text-gray-700 mb-2">New Email:</label>
         <input
                type="email"
                 id="email"
                 name="email"
           value={email}
      onChange={(e) => setEmail(e.target.value)}
      className="w-full p-2 border rounded-md"
      maxlength="40" 
      placeholder="Enter new email"
      required
    />
    <div className="mt-4 flex space-x-2">
      <button
        className="bg-red-700 text-white px-4 py-2 rounded-md"
        type="button"
        onClick={handleCloseModal}
      >
        Cancel
      </button>
      <button
        className="bg-gray-600 text-white px-4 py-2 rounded-md"
        type="submit"
      >
        Save
      </button>
         </div>
       </form>
   </div>

          
      ) : (  <form className =" bg-white rounded-xl w-full inset-0" onSubmit={handleOtpSubmit}>
                 
        <div className="mb-2 p-4">
            <label htmlFor="otp" className="block text-sm font-medium text-gray-700">
                Enter OTP
            </label>
            <input
                type="text"
                id="otp"
                name="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                maxlength="6" 
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                required
            />
        </div>
        <div className="flex">
          
        <button
        className="bg-red-700 ml-4 text-white px-4 py-2 rounded-md"
        type="button"
        onClick={handleCloseModall}
      >
        Cancel
      </button>
  <button
    type="submit"
    className=" justify-center items-center bg-gray-600 text-white  px-2 ml-4 mr-4 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-600"
  >
    Verify OTP
  </button>
</div>

      <p className='p-2 text-sm mb-4' > {message && <p className="text-green-500 text-center mt-4">{message}</p>}</p> 
    </form>)}


       
        </div>
     
      )}
    


    

      <div className="flex">
        <main className="flex-grow p-2">
          <div className="max-w-xl mx-auto p-4 bg-white rounded-xl shadow-xl mt-12">
            {user?.profilePicture && (
              <img src={`https://mybackend-new.onrender.com/${user.profilePicture}`} alt="Profile" className="w-32 h-32 rounded-full mx-auto mb-4" />
            )}
            <h2 className="page text-2xl font-bold mb-2 text-center">Welcome, {user?.username}!</h2>
            {isEditing ? (
              <form onSubmit={handleFormSubmit} className="space-y-1">
                <div>
                  <label className="block text-sm font-medium text-center mb-1 text-gray-700">Business description</label>
               <textarea
                   name="description"
                   value={user?.description}
                   onChange={handleInputChange}
                   className="w-full max-h-50 px-6 py-4 border border-gray-300 rounded-xl resize-none"
                   rows="4"
                   maxlength="200"  
                   required
                   />


                </div>


                <div>
                  <label className="block text-sm font-medium text-center mb-1 text-gray-700">Location/Address</label>

                <textarea
                   name="location"
                   value={user?.location}
                   onChange={handleInputChange}
                   className="w-full  px-6 py-4 border border-gray-300 rounded-xl resize-none"
                   rows="2"
                   maxlength="200"  
                   required
                />


                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Username:</label>
                  <input
                    type="text"
                    name="username"
                    value={user?.username}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2  border border-gray-300 rounded-md"
                    maxlength="25" 
                    required
                  />
                </div>

                
                
                <div className="mb-6">
  <label className="block text-sm font-medium text-gray-700">
    Phone Number (e.g. 9875...):
  </label>
  <div className="flex items-center">
    {/* Prefix for the phone number */}
    <div className="flex items-center bg-gray-100 p-2 rounded-l-md">
      <span className="text-xl">🇲🇼</span>
      <span className="ml-2">+265</span>
    </div>
    {/* Input field for the remaining phone number */}
    <input
      type="text"
      name="phoneNumber"
      value={trimmedPhone}
      onChange={(e) => {
        const input = e.target.value;
        // Validate input: allow only 9 digits and ensure it doesn't start with 0
        if (/^[1-9][0-9]{0,8}$/.test(input) || input === '') {
          handleInputChange(e);
        }
      }}
      className="w-full px-3 py-2 border border-gray-300 rounded-r-md focus:outline-none focus:border-blue-500"
      maxLength="9"
      required
    />
  </div>
  <small className="text-gray-500">Phone number should be 9 digits and not start with 0.</small>
</div>

             
                <div>
                  <label className="block text-sm font-medium text-gray-700">Gender:</label>
                  <select
                    name="gender"
                    value={user?.gender}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    required
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                <div className="relative mb-4">
  <label className="block text-sm font-medium text-gray-700">New Password:</label>
  <input
    type={showPassword ? 'text' : 'password'}
    name="password"
    value={user?.password}
    onChange={handleInputChange}
    className={`w-full px-3 py-2 pr-10 border rounded-md focus:outline-none ${
      user?.password && !isValidPassword(user.password)
        ? 'border-red-500'
        : 'border-gray-300 focus:border-blue-500'
    }`}
    maxLength="25"
  
  />
  
  {user?.password && !isValidPassword(user.password) && (
    <small className="text-red-500">
      Password must be at least 8 characters, include one number, and one uppercase letter.
    </small>
  )}
       <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute right-3 top-1/2 transform -translate-y-1 text-gray-500"
        >
          {showPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
        </button>
                </div>
                {notice && <p className="text-red-500 text-sm text-center mt-4">{notice}</p>}
                <div className='flex grow pt-2' >
                <button
                    type="button"
                    onClick={() => setIsEditing(false)}
                    className=" bg-red-700 text-white py-2 px-4 pr-4 rounded-md"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onChange={handleFormSubmit}
                    className=" bg-gray-700 ml-2 text-white py-2 px-4 rounded-md hover:bg-gray-600"
                  >
                    Save
                  </button>
                </div>
                
              </form>
            ) : (


              
             
              <div id="personal-info" className=" space-y-4">
               
                <p className="text-center mb-4">Here is your profile information:</p>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Username:</label>
                  <p className="text-gray-700">{user?.username}</p>
                </div>
                <div className="flex items-center">
  <div>
    <label className="block text-sm font-medium text-gray-700">Email:</label>
    <p className="text-gray-700">{user?.email}</p>
  </div>
  <button className="ml-auto mr-4" onClick={handleButtonClick}>
        <FaRegEdit size={25} />
      </button>


</div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Phone Number:</label>
                  <p className="text-gray-700">{user?.phoneNumber}</p>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700">Gender:</label>
                  <p className="text-gray-700">{user?.gender}</p>
                </div>

                <div className="justify-center items-center mb-4">
  <label className="block font-bold text-gray-700 text-center mb-2">
    My business description
  </label>
  <p className="text-gray-700 text-center text-sm  max-h-40 overflow-hidden text-ellipsis whitespace-normal resize-none">
    {user?.description}
  </p>

</div>
  

<div className="justify-center items-center mb-2">
  <label className="block font-bold text-gray-700 text-center mb-2">
    My Location
  </label>
  <p className="text-gray-700 text-center text-sm  max-h-40 overflow-hidden text-ellipsis whitespace-normal resize-none">
    {user?.location}
  </p>
</div>


                <button
                    onClick={() => setIsEditing(true)}
                     className="w-full bg-gray-700 text-white py-2 px-4 rounded-md justify-center flex items-center"
                     >
                     <FaUserEdit className="mr-2" />
                      Edit Profile
                  </button>

              </div>
            )}
          </div>
        </main>
    
      </div>
      <br/><br/>
  
    </div>

   </div> 
    )}
 </div>  
  );
};

export default Welcome;
